<template>
  <button
    :disabled="loading"
    class="inline-flex items-center text-white px-3 py-2 cursor-pointer duration-300 hover:bg-info-4"
    :class="[
      selected ? 'bg-info-4' : ' bg-info-1',
      circle ? 'rounded-full w-10 h-10 justify-center' : 'rounded',
    ]"
    @click.stop="toggle(!selected)"
  >
    <span>
      <font-awesome-icon
        :icon="!selected ? ['fas', 'plus'] : ['fas', 'check']"
      />
      <span
        v-show="!circle"
        class="text-sm ml-1"
        v-text="!selected ? text : '已加入'"
      ></span>
    </span>
  </button>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: [Number, Boolean],
      default: false,
    },
    circle: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '加入收藏題庫',
    },
  },
  emits: ['toggle'],
  setup(props, { emit }) {
    const toggle = (select) => {
      emit('toggle', select);
    };
    return { toggle };
  },
};
</script>
