import { reactive } from 'vue';
export default () => {
  const confs = reactive([]);
  const updateConfs = ({ id, conf }) => {
    const idx = confs.findIndex((x) => {
      return x.id === id;
    });
    if (idx > -1) {
      confs[idx].conf = conf;
    }
  };
  const setConfs = (rows) => {
    rows.forEach((x) => {
      if (x.confIndex) {
        confs.push({ id: x.id, conf: x.confIndex.id });
      }
    });
  };
  return { confs, updateConfs, setConfs };
};
