<template>
  <div>
    <!-- for moblie start -->
    <div v-if="isMobile" class="space-y-4">
      <div
        v-for="(item, index) in rowsData.rows"
        :key="item.id"
        class="bg-white shadow rounded cursor-pointer"
        @click="openDetail(item)"
      >
        <div class="p-4 border-b">{{ index + 1 }}. {{ item.text }}</div>
        <div class="p-4 flex justify-between items-center">
          <div class="text-sm text-gray">{{ item.id }}</div>
          <AddBtn
            v-if="columns.indexOf('like') > -1"
            :selected="saveds.indexOf(item.id) > -1"
            :circle="false"
            @toggle="
              updateSaved({
                id: item.id,
                saved: !saveds.indexOf(item.id) > -1,
                row: item,
              })
            "
          />
        </div>
      </div>
      <BtnMore v-if="!isEnding" @more="more"></BtnMore>
    </div>
    <!-- for moblie END -->
    <!-- for pc start -->
    <el-table
      v-else
      v-loading="loading"
      :data="rowsData.rows"
      style="width: 100%"
      header-cell-class-name="text-gray-light font-normal text-sm"
      header-align="center"
      :default-sort="{
        prop: form.orderItem,
        order: form.order == 0 ? 'ascending' : 'descending',
      }"
      :cell-class-name="status"
      @row-click="openDetail"
      @sort-change="onSorter"
      class="cursor-pointer"
    >
      <!-- 編號 -->
      <el-table-column
        prop="index"
        label="編號"
        width="80"
        align="center"
        header-align="center"
      >
        <template #default="scope">
          <div class="flex flex-col justify-center items-center">
            <div>{{ scope.$index + 1 }}</div>
            <div
              v-if="scope.row.group != 0"
              class="rounded px-2 bg-yellow text-sm mt-1"
            >
              題組
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 專業科目 -->
      <el-table-column
        v-if="columns.indexOf('name') > -1"
        label="試卷名稱/科目"
        prop="subject"
        min-width="130"
        header-align="left"
      >
        <template #default="scope">
          <el-popover
            v-if="scope.row.groupInfo"
            placement="top-start"
            :width="200"
            trigger="hover"
          >
            <div v-html="scope.row.groupInfo"></div>
            <template #reference>
              <div class="text-black">
                {{ scope.row.exam.name }}
              </div>
              <div class="text-gray-light">
                {{ scope.row.subject.name }}
              </div>
            </template>
          </el-popover>
          <div v-else>
            <div class="text-black">
              {{ scope.row.exam.name }}
            </div>
            <div class="text-gray-light">
              {{ scope.row.subject.name }}
            </div>
          </div>
        </template>
      </el-table-column>

      <!-- 題目 -->
      <el-table-column label="問題本文" prop="text" header-align="left" />

      <!-- 屬性 -->
      <el-table-column
        v-if="columns.indexOf('attribute') > -1"
        label="屬性"
        width="100"
        prop="attribute"
        align="center"
        header-align="center"
      >
        <template #default="scope">
          <div class="text-black">
            {{ getJoinString(scope.row.attribute, 'name') }}
          </div>
        </template>
      </el-table-column>

      <!-- 答題次數 -->
      <el-table-column
        prop="ansCnt"
        :sortable="sortable ? 'custom' : false"
        label="答題次數"
        class-name="text-gray-light"
        width="100"
        align="center"
        header-align="center"
      />

      <!-- 平均正確率 -->
      <el-table-column
        :sortable="sortable ? 'custom' : false"
        label="平均正確率"
        width="120"
        prop="avgCorrectRate"
        align="center"
        header-align="center"
      >
        <template #default="scope">
          <div
            class="inline-block rounded py-1 px-3 text-white"
            :style="{
              backgroundColor: getScoreColor(scope.row.avgCorrectRate),
            }"
          >
            {{ removePercentSign(scope.row.avgCorrectRate) }}%
          </div>
        </template>
      </el-table-column>

      <!-- 答題日期 -->
      <el-table-column
        v-if="columns.indexOf('testerDate') > -1"
        prop="date"
        label="答題日期"
        class-name="text-gray-light"
        width="100"
        :sortable="sortable ? 'custom' : false"
        align="center"
        header-align="center"
      />
      <el-table-column
        v-else
        prop="date"
        label="考試日期"
        class-name="text-gray-light"
        width="100"
        :sortable="sortable ? 'custom' : false"
        align="center"
        header-align="center"
      />
      <el-table-column
        v-if="columns.indexOf('ansStatus') > -1"
        prop="ansStatus"
        :sortable="sortable ? 'custom' : false"
        label="答題狀況"
        width="100"
        align="center"
        header-align="center"
      >
        <template #default="scope">
          <div v-if="scope.row.ansStatus === 1">
            <font-awesome-icon
              :icon="['fas', 'check']"
              class="text-xl text-info-4"
            />
          </div>
          <div v-else-if="scope.row.ansStatus === 2">
            <font-awesome-icon
              :icon="['fas', 'times']"
              class="text-xl text-danger"
            />
          </div>
          <div v-else>-</div>
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.indexOf('like') > -1"
        prop="saved"
        fixed="right"
        label="加入收藏題庫"
        width="125"
        align="center"
        header-align="center"
      >
        <template #default="scope">
          <AddBtn
            :selected="saveds.indexOf(scope.row.id) > -1"
            :circle="true"
            @toggle="
              (saved) =>
                updateSaved({ id: scope.row.id, saved, row: scope.row })
            "
          />
        </template>
      </el-table-column>
      <el-table-column
        v-if="columns.indexOf('conf') > -1"
        prop="confIndex"
        :sortable="sortable ? 'custom' : false"
        label="信心指數"
        width="100"
        align="center"
        header-align="center"
      >
        <template #default="scope">
          <img
            v-if="scope.row.id && confs.length && confOpts.length"
            class="w-8 mx-auto inline-block"
            :src="getConfIcon(scope.row.id)"
          />
        </template>
      </el-table-column>
      <template #append>
        <BtnMore v-if="!isEnding" @more="more"></BtnMore>
      </template>
      <template #empty>
        <div class="text-center">{{ emptyText }}</div>
      </template>
    </el-table>
    <!-- for pc End -->
    <El-Dialog v-model="dialog.state.show" destroy-on-close custom-class="full">
      <Quest v-if="row.id" v-loading="detailLoading" :row="row" />
    </El-Dialog>
  </div>
</template>

<script>
import { inject, provide, reactive, toRefs, watch } from 'vue';
import AddBtn from '@/components/AddBtn.vue';
import BtnMore from '@/components/BtnMore.vue';
import { getJoinString, getScoreColor, removePercentSign } from '@/utils';
import { ElTable, ElTableColumn, ElDialog, ElPopover } from 'element-plus';
import { Plus } from '@element-plus/icons-vue';
import useDialog from '@/hook/useDialog';
import useQuestion from './useQuestion';

// <!-- 需要加判斷是哪一種quest -->
// import { confs } from '@/utils/Config.js';
import Quest from '@/modules/quest/Result.vue';

export default {
  components: {
    AddBtn,
    ElTable,
    ElTableColumn,
    ElDialog,
    ElPopover,
    Quest,
    BtnMore,
  },
  props: {
    emptyText: {
      type: String,
      default: '目前沒有資料',
    },
    columns: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: '',
    },
    clickable: {
      type: Boolean,
      default: true,
    },
    sortable: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['sorter'],
  setup(props, { emit }) {
    const isMobile = inject('isMobile');
    const { type, clickable } = toRefs(props);
    const { guard } = inject('auth');
    const { confOpts } = inject('store');
    const viewDataJson = false; // 測試用，把第一個資料變成 json 檢視
    const {
      rowsData,
      loading,
      updateForm,
      more,
      isEnding,
      confs,
      updateConfs: listUpdateConf,
      saveds,
      updateSaved: listUpdateSaved,
    } = inject('questions');

    const form = reactive({
      orderItem: 'date',
      order: 1,
    });

    const onSorter = (e) => {
      form.order = e.order === 'ascending' ? 0 : 1;
      form.orderItem = e.prop;
      form.pg = 1;
      updateForm({ ...form });
    };

    const dialog = useDialog();

    const {
      row,
      fetchDetail,
      loading: detailLoading,
      updateSaved: detailUpdateSaved,
      updateConf: detailUpdateConf,
    } = useQuestion({ type: type.value });

    const openDetail = (row) => {
      if (!clickable.value) return;
      if (!guard()) return;
      dialog.toggle({ show: true });
      fetchDetail(row.id);
    };

    const permission = inject('permission');
    const status = ({ row, column }) => {
      return !permission.check(row.member_status) && column.property !== 'saved'
        ? 'soft-disabled'
        : '';
    };

    const updateSaved = async ({ id, saved }) => {
      // if (!(await permission.dialog(row.member_status))) return;
      detailUpdateSaved(saved);
      listUpdateSaved({ id, saved });
    };
    const updateConf = async ({ id, conf }) => {
      if (!(await permission.dialog(row.member_status))) return;
      listUpdateConf({ id, conf });
      detailUpdateConf(conf);
    };

    provide('actions', {
      saveds,
      updateSaved,
      updateConf,
    });

    const getConfIcon = (id) => {
      const conf = confs.find((x) => x.id == id);
      return conf ? confOpts[conf.conf].icon : confOpts[0];
    };

    return {
      viewDataJson,
      getJoinString,
      isEnding,
      loading,
      onSorter,
      getScoreColor,
      form,
      rowsData,
      updateSaved,
      updateConf,
      Plus,
      more,
      dialog,
      detailLoading,
      openDetail,
      row,
      saveds,
      confs,
      confOpts,
      getConfIcon,
      removePercentSign,
      status,
      isMobile,
    };
  },
};
</script>
