import { ref, reactive } from 'vue';
import Question from '@/api/Question';

export default ({ type = '' }) => {
  const row = reactive({});
  const loading = ref(false);
  const fetchDetail = (id) => {
    loading.value = true;
    Question.detail({ qId: id, type })
      .then((res) => {
        Object.assign(row, res.data);
      })
      .catch((err) => {})
      .finally(() => {
        loading.value = false;
      });
  };
  const updateSaved = (saved = true) => {
    row.saved = saved;
  };
  const updateConf = (conf = true) => {
    row.conf = conf;
  };

  return { row, fetchDetail, updateSaved, updateConf, loading };
};
